import React from "react";
import ContentPage from '../components/ContentPage';
import PageImage from '../components/PageImage';

import image from '../images/undraw_message_sent.svg';

const ContactPage = () => {

    return <ContentPage>
        <h1>Contact Us</h1>
        <p>We would be delighted to learn about your project and help you to find the right solution to achieve your
            business's objectives.</p>
        <p>Please drop us an email at info@coralpeak.com and we'll get back to you right away.</p>
        <PageImage image={image} alt='Email' />
    </ContentPage>
}

export default ContactPage